<!-- @format -->

<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8">
        <b-form @submit.prevent style="background-color: #fff">
          <div class="woc">
            <b-card class="invoice-preview-card">
              <!-- Header -->
              <b-row class="invoice-padding pb-0">
                <b-col cols="4">
                  <div>
                    <div class="logo-wrapper">
                      <h3 class="text-primary invoice-logo">保单添加</h3>
                    </div>
                  </div>
                </b-col>
                <b-col cols="4">
                  <div class="invoice-number-date mt-md-0">
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                      <h4 class="invoice-title">会员ID</h4>
                      <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input v-model="formData.create_id" @change="vip_select(formData.create_id)" />
                      </b-input-group>
                    </div>
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                      <h4 class="invoice-title">会员名称</h4>
                      <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input v-model="formData.create_name" disabled />
                      </b-input-group>
                    </div>
                    <div class="d-flex align-items-center justify-content-md-end mb-1">
                      <h4 class="invoice-title">会员等级</h4>
                      <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="HashIcon" />
                        </b-input-group-prepend>
                        <b-form-input v-model="formData.create_level" disabled />
                      </b-input-group>
                    </div>
                  </div>
                </b-col>
              </b-row>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Invoice Client & Payment Details -->
              <b-row class="invoice-spacing mb-0 ml-2">
                <b-col cols="8" class="mb-lg-1">
                  <h6 class="mb-2">保单号:</h6>
                  <validation-provider #default="{ errors }" name="保单号">
                    <b-form-input v-model="formData.number" placeholder="请输入保单号" :state="errors.length > 0 ? false : null" :disabled="yulandis" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row class="invoice-spacing mb-0 ml-2">
                <b-col cols="8" class="mb-lg-1">
                  <h6 class="mb-2">选择产品:</h6>
                  <validation-provider #default="{ errors }" name="保单号">
                    <!--                  <b-form-input v-model="formData.product_id" :state="errors.length > 0 ? false : null" placeholder="请输入保单号" />-->
                    <!--                  <small class="text-danger">{{ errors[0] }}</small>-->
                    <b-row style="width: 100%">
                      <b-col cols="12" class="mb-1">
                        <vSelect placeholder="请选择公司" v-model="c" :options="comptry" :reduce="item => item.id" label="title" :disabled="yulandis" />
                      </b-col>
                      <b-col cols="12" class="mb-1">
                        <vSelect placeholder="请选择大类" v-model="big" :options="bigcoptry" :reduce="item => item.type_id" label="type_name" :disabled="yulandis || !c" />
                      </b-col>
                      <!--                      <b-col>-->
                      <!--                        <vSelect placeholder="请选择分类" v-model="d" :options="cate" :reduce="item => item.id" label="title" :disabled="yulandis || !c || !big"></vSelect>-->
                      <!--                      </b-col>-->
                      <b-col cols="12" class="mb-1">
                        <vSelect placeholder="请选择产品" v-model="formData.product_id" label="title" :reduce="item => item.id" :options="productList" :disabled="yulandis || !c || !big" @input="changeCompu" />
                      </b-col>
                    </b-row>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row class="invoice-spacing mb-0 ml-2">
                <b-col cols="8" class="mb-lg-1">
                  <h6 class="mb-2">被保险人:</h6>
                  <validation-provider #default="{ errors }" name="被保险人">
                    <b-form-input v-model="formData.protect_user" :state="errors.length > 0 ? false : null" placeholder="请输入被保险人" :disabled="yulandis" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="invoice-spacing mb-0 ml-2">
                <b-col cols="8" class="mb-lg-1">
                  <h6 class="mb-2">保单金额:</h6>
                  <validation-provider #default="{ errors }" name="保单金额">
                    <!--                    <b-form-input v-model="formData.money" :state="errors.length > 0 ? false : null" placeholder="请输入保单金额" :disabled="yulandis" />-->
                    <b-form-input v-model="money" :state="errors.length > 0 ? false : null" placeholder="FaceAmount" :disabled="yulandis || !rate" />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="invoice-spacing mb-0 ml-2">
                <b-col cols="8" class="mb-lg-1">
                  <h6 class="mb-2">签署地:</h6>
                  <validation-provider #default="{ errors }" name="签署地">
                    <b-form-input v-model="formData.sign_city" :state="errors.length > 0 ? false : null" placeholder="请输入签署地" :disabled="yulandis" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row class="invoice-spacing mb-0 ml-2">
                <b-col cols="8" class="mb-lg-1">
                  <h6 class="mb-2">成交时间:</h6>
                  <validation-provider #default="{ errors }" name="成交时间">
                    <flat-pickr v-model="utcTime" :class="['form-control-time', yulandis ? 'graydis' : '']" :config="timePickerConfig" placeholder="请选择成交时间" :disabled="yulandis" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row class="invoice-spacing mb-0 ml-2">
                <b-col cols="8" class="mb-lg-1">
                  <h6 class="mb-2">派发率(%):</h6>
                  <b-form-input
                    v-model="formData.rate"
                    placeholder="派发率(%)"
                  />
                </b-col>
              </b-row>

              <b-row class="invoice-spacing mb-0 ml-2">
                <b-col cols="8" class="mb-lg-1">
                  <h6 class="mb-2">续费派发率(%):</h6>
                  <b-form-input
                    v-model="formData.renew_rate"
                    placeholder="续费派发率(%)"
                  />

                </b-col>
              </b-row>

              <b-row class="invoice-spacing mb-0 ml-2">
                <b-col cols="8" class="mb-lg-1">
                  <h6 class="mb-2">超额派发率(%):</h6>
                  <b-form-input
                    v-model="formData.over_rate"
                    placeholder="超额派发率(%)"
                  />

                </b-col>
              </b-row>

              <b-card-body class="invoice-padding">
                <div ref="form" class="repeater-form" :style="{ height: trHeight }">
                  <b-row v-for="(item, index) in formData.share_rate" :key="index" ref="row" class="pb-2">
                    <b-col cols="12">
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                          <b-col cols="12" lg="3"> 会员id </b-col>
                          <b-col cols="12" lg="3"> 会员名称 </b-col>
                          <b-col cols="12" lg="3"> 会员等级 </b-col>
                          <b-col cols="12" lg="3"> 分担比 </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-2">
                          <b-col cols="12" lg="3">
                            <b-form-input
                              v-model="formData.share_rate[index].search_id"
                              type="number"
                              class="mb-2"
                              @blur="searchUserId(index)"
                              @keyup.enter="searchUserId(index)"
                              placeholder="请输入会员id"
                              :disabled="yulandis"
                            />
                          </b-col>
                          <b-col cols="12" lg="3">
                            <b-form-input v-model="formData.share_rate[index].create_name" class="mb-2" disabled placeholder="请输入会员id" />
                          </b-col>
                          <b-col cols="12" lg="3">
                            <b-form-input v-model="formData.share_rate[index].create_level" class="mb-2" disabled placeholder="请输入会员id" />
                          </b-col>
                          <b-col cols="12" lg="3">
                            <b-form-input v-model="formData.share_rate[index].share_rate" class="mb-2" placeholder="请输入该会员分担比" :disabled="!formData.share_rate[index].create_name || yulandis" />
                          </b-col>
                        </b-row>
                        <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                          <feather-icon size="16" icon="XIcon" class="cursor-pointer" @click="removeItem(index)" />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary" @click="addNewItemInItemForm"> 添加分担会员 </b-button>
              </b-card-body>

              <b-row class="invoice-spacing ml-2">
                <b-col cols="8" class="mb-lg-1">
                  <h6 class="mb-2">保单备注:</h6>
                  <b-form-textarea v-model="formData.desc" placeholder="请输入保单备注" :disabled="yulandis" />
                </b-col>
              </b-row>
            </b-card>
          </div>
        </b-form>
      </b-col>

      <b-col cols="12" md="4" xl="3" class="invoice-actions mt-md-0 mt-2">
        <b-card>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mb-75" block @click.prevent="submitAddProduct">确认</b-button>
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="mb-75" block @click="yulandis = !yulandis">
            {{ yulandis ? '取消预览' : '预览' }}
          </b-button>
          <!--          <b-form-group label="电子邮件" label-for="register-email">-->
          <!--            <validation-provider #default="{ errors }" name="email" vid="email" rules="required">-->
          <!--              <b-form-input id="register-email" v-model="email" name="register-email" :state="errors.length > 0 ? false : null" disabled />-->
          <!--              <small class="text-danger">{{ errors[0] }}</small>-->
          <!--            </validation-provider>-->
          <!--          </b-form-group>-->
          <!--          <b-form-group label="验证码" label-for="register-check_code">-->
          <!--            <validation-provider #default="{ errors }" name="check_code" vid="check_code" rules="required" style="display: flex; justify-content: space-between">-->
          <!--              <b-form-input id="register-check_code" v-model="check_code" name="register-check_code" :state="errors.length > 0 ? false : null" placeholder="请输入邮箱验证码" style="width: 70%" />-->
          <!--              <b-button v-if="text === '发送'" variant="primary" style="width: 28%" :disabled="disable" @click="fs_email">-->
          <!--                {{ text }}-->
          <!--              </b-button>-->
          <!--              <b-button v-if="text !== '发送'" style="width: 28%" :disabled="disable" @click="fs_email">-->
          <!--                {{ text }}-->
          <!--              </b-button>-->
          <!--            </validation-provider>-->
          <!--            <b-button variant="primary" @click="sub_email" style="width: 100%; margin-top: 10px"> 提交 </b-button>-->
          <!--          </b-form-group>-->
          <b-button v-if="yx" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="mb-75" block @click="downLoad"> 下载 </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ref, getCurrentInstance, computed, nextTick, onMounted, reactive, watch } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ListManagerModule from '@/views/GeneralManagement/ProductManager/List/ListManagerModule'

import { BRow, BCol, BCard, BCardBody, BButton, BForm, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, VBToggle } from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import axiosIns from '@/libs/axios'
import { useToast } from 'vue-toastification/composition'
import orderModule from '@/views/GeneralManagement/OrderManager/OrderList/OrderManagerList'
// import invoiceStoreModule from '../invoiceStoreModule'
// import InvoiceSidebarAddNewCustomer from '../InvoiceSidebarAddNewCustomer.vue'
import zh from 'flatpickr/dist/l10n'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  data() {
    return {
      text: '发送',
      disable: false,
      email: '',
      check_code: '',
      required,
      yx: false,
      time: 60,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
    // this.email = localStorage.getItem("email");
    this.email = localStorage.getItem('email')
    const time = localStorage.getItem('time')
    // 判断是否大于0
    if (time && time > 0) {
      //  解决刷新页面按钮有空白情况
      this.text = `${time}秒`
      this.time = time
      this.send()
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    getUtc(val) {
      // 获取当前 UTC 洛杉矶 时区
      const date = new Date(val)
      const utc = date.getTime() + date.getTimezoneOffset() * 60000
      const nd = new Date(utc + 3600000 * -8).getTime()
      return nd
    },
    send() {
      // 点击按钮，禁用按钮，防止多次点击
      this.disable = true
      this.text = `${this.time}s`
      localStorage.setItem('time', this.time)
      this.timer = setInterval(() => {
        if (this.time > 0) {
          this.time--
          localStorage.setItem('time', this.time)
          this.text = `${this.time}s`
        } else {
          clearInterval(this.timer)
          this.time = 60
          this.disable = false
          this.text = '发送'
        }
      }, 1000)
    },
    sub_email() {
      useJwt.fs_pdf({ check_code: this.check_code }).then(response => {
        console.log(response)
      })
      this.yx = true
    },
    fs_email() {
      console.log(this.email)
      useJwt.fs_pdf_email({ email: this.email }).then(response => {
        console.log(response)
        if (response.code == 0) {
          this.send()
        } else {
          this.$toast('发送错误，请正确填写邮箱')
        }
      })
    },
    vip_select(e) {
      axiosIns.get(`/user/getUserInfoById?id=${e}`).then(response => {
        console.log(response.data.id, e)
        if (response.data.id == e) {
          this.formData.create_name = response.data.real_name
          this.formData.user_id = response.data.id
          this.formData.create_level = response.data.level.name
        } else {
          this.formData.create_name = ''
          this.formData.create_id = ''
          this.formData.create_level = ''
        }
      })
    },
    addNewItemInItemForm() {
      if (this.formData.share_rate.length < 6) {
        this.$refs.form.style.overflow = 'hidden'
        this.formData.share_rate.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight)
          setTimeout(() => {
            this.$refs.form.style.overflow = null
          }, 350)
        })
      } else {
        this.$bvToast.toast('最多只能添加6个分成比例', {
          title: '提示',
          variant: 'warning',
          solid: true,
          autoHideDelay: 2000,
        })
      }
    },
    removeItem(index) {
      this.formData.share_rate.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      setTimeout(() => {
        this.$nextTick(() => {
          setTimeout(() => {
            this.trSetHeight(this.$refs.form.scrollHeight)
          }, 1000)
        })
      }, 200)
    },
    forceUpdated() {
      this.$forceUpdate()
    },
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const toast = useToast()
    const formData = reactive({
      product_id: '',
      // status: '1',
      number: '',
      deal_time: '',
      order_tp: '',
      user_id: '',
      share_rate: [],
      protect_user: '', // 被保人
      sign_city: '', // 签署地
      money: '', // 保单金额
      desc: '',
      create_name: '',
      create_id: '',
      create_level: '',
      over_rate: ''
    })
    const money = ref('')
    const rate = ref('')

    const utcTime = ref('')
    const timePickerConfig = {
      enableTime: true,
      dateFormat: 'Y-m-d H:i:ss',
      // 时区
      time_24hr: true,
      // 时区
      utc: true,
      locale: zh.zh, // locale for this instance only
      onChange(selectedDates, dateStr, instance) {
        // console.log(selectedDates, dateStr, instance)
        // utcTime.value = proxy.getUtc(dateStr)
        formData.deal_time = dateStr
      },
    }
    // const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    // if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)
    //
    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    // })

    const productList = reactive([])
    const casePagae = ref(1)
    const total = ref(0)
    const hasPrevPage = computed(() => casePagae.value > 1)
    const hasNextPage = computed(() => casePagae.value < Math.ceil(total.value / 20))

    // 会员列表

    const itemFormBlankItem = {
      user_id: undefined,
      share_rate: undefined,
    }

    // onMounted(() => {
    //   nextTick(() => {
    //     // fetchProdList()
    //     // fetchSignCity()
    //     axiosIns.get("/user/list").then((response) => {
    //       if (response.code === 0) {
    //         vipList.push(...response.data.list);
    //       }
    //     });
    //     // 获取默认会员信息
    //     axiosIns.get("/order/create-user").then((response) => {
    //       formData.create_name = response.data.real_name;
    //       formData.create_id = response.data.id;
    //       formData.create_level = response.data.level.name;
    //     });
    //     // formData.share_rate.push(JSON.parse(JSON.stringify(itemFormBlankItem)))
    //   });
    // });

    const isLoading = ref(false)

    const INVOICE_APP_STORE_MODULE_NAME = 'user-order'
    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, orderModule)

    const submitAddProduct = () => {
      console.log(formData)
      isLoading.value = true
      // proxy.$refs.registerForm.validate().then(isOk => {
      //   if (isOk) {
      //     if (error.text || error.component) {
      //       toast.error(error.text)
      //       isLoading.value = false
      //       return
      //     }
      store
        .dispatch('user-order/orderCreate', {
          ...formData,
        })
        .then(response => {
          if (response.code === 0) {
            toast.success(response.msg || '添加成功')
            setTimeout(() => {
              isLoading.value = false
              proxy.$router.back()
            }, 1000)
          } else {
            isLoading.value = false
            toast.error(response.msg)
          }
        })
        .catch(() => {
          toast.error('添加失败')
          isLoading.value = false
        })
      //   } else {
      //     toast.error('请将表单填写完整')
      //     isLoading.value = false
      //   }
      // })
    }

    const downLoad = () => {
      document.body.style.cursor = 'wait'
      console.log(document.querySelector('.woc').offsetWidth)
      setTimeout(() => {
        // eslint-disable-next-line new-cap
        const doc = new jsPDF()

        html2canvas(document.querySelector('.woc '), {
          height: document.querySelector('.woc').scrollHeight + 300,
          width: document.querySelector('.woc').offsetWidth + 100,
          // height: document.querySelector('.woc').scrollHeight,
          backgroundColor: '#fff',
          scale: 1,
          removeContainer: true,
          allowTaint: true,
          // 背景大小
          bcckgroundSize: 'cover',
        }).then(canvas => {
          const imgData = canvas.toDataURL('image/jpeg')
          doc.addImage(imgData, 'JPEG', 0, 0, 320, 320, '')
          doc.save('invoice.pdf')
          setTimeout(() => {
            document.body.style.cursor = 'default'
          }, 1000)
        })
      }, 1000)
    }

    const searchId = ref(null)
    const searchUserId = val => {
      if (!formData.share_rate[val].search_id) {
        formData.share_rate[val] = {}
        proxy.forceUpdated()
        return
      }
      axiosIns.get(`/user/getUserInfoById?id=${formData.share_rate[val].search_id}`).then(res => {
        if (res.code == 0) {
          // 搜索原有数据,查找是否存在该树
          const findIndex = formData.share_rate.findIndex(item => item.user_id == res.data.id)
          if (findIndex < 0) {
            nextTick(() => {
              formData.share_rate[val].user_id = res.data.id
              formData.share_rate[val].share_rate = res.data.share_rate
              formData.share_rate[val].create_name = res.data.real_name
              formData.share_rate[val].create_level = res.data.level.name
            })
            proxy.forceUpdated()
          } else if (findIndex == 0) {
            toast('该会员已存在!')
            formData.share_rate[val] = {}
            proxy.forceUpdated()
          }
        } else {
          toast(res.msg, {
            type: 'error',
            duration: 2000,
          })
          formData.share_rate[val] = {}
          proxy.forceUpdated()
        }
      })
    }

    const comptry = reactive([])
    const cate = reactive([])
    const bigcoptry = reactive([])
    const c = ref('')
    const d = ref('')
    const big = ref('')

    onMounted(() => {
      axiosIns.get('/pro-brand/getBrandList').then(res => {
        if (res.code === 0) {
          comptry.push(...res.data)
        }
      })
      axiosIns.get('/pro-cate/getProductType').then(res => {
        if (res.code === 0) {
          bigcoptry.push(...res.data)
        }
      })
      axiosIns.get('/pro-cate/getCateList').then(res => {
        if (res.code === 0) {
          cate.push(...res.data)
        }
      })
    })
    watch([c, big, money], val => {
      if (big.value == 2) {
        formData.order_tp = Number(rate.value) * Number(money.value) || undefined
      } else {
        formData.order_tp = undefined
      }
      formData.money = money.value
      if (c.value && big.value) {
        axiosIns.get(`/product/getProduct?brand_id=${c.value}&cate_id=${big.value}`).then(res => {
          if (res.code === 0) {
            productList.splice(0, productList.length, ...res.data)
          }
        })
      }
    })
    // 'formData.deal_time'

    const yulandis = ref(false)
    const changeCompu = val => {
      formData.product_id = val
      rate.value = productList.find(item => item.id == val).rate
      // console.log(productList.find(item => item.id == val))
      // 合并formdata

      // formData = { ...formData, ...productList.find(item => item.id == val) }
      Object.assign(formData, productList.find(item => item.id == val))
      // 强制更新
      proxy.forceUpdated()
    }
    return {
      changeCompu,
      money,
      rate,
      yulandis,
      c,
      d,
      big,
      comptry,
      cate,
      bigcoptry,
      itemFormBlankItem,
      hasNextPage,
      hasPrevPage,
      formData,
      submitAddProduct,
      isLoading,
      timePickerConfig,
      searchId,
      searchUserId,
      productList,
      downLoad,
      utcTime
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
.woc {
  //overflow: hidden;
  background-color: transparent;

  >>> div.card {
    background-color: #ffffff !important;
  }
}
.graydis {
  background-color: #efefef !important;
}
.form-control-time {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  display: block;
  width: 100%;
  height: 2.714rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
